import React, {useEffect, useState} from 'react';
import {toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import Validation from '../SignupValidation';
import Navbar from '../Navbar';
import axios from 'axios';
// import { empmaster } from './Empmaster';



const Changepassword = () => {    
    const [empdata, setEmpdata ] = useState('');
    const [password, setPassword] = useState('');
    const [userData, setUserData] = useState({});  
    // const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleResetPassword = async(event) => {
        // console.warn({empdataid, empdata, password});
        event.preventDefault();
        const data = {
            empid : userData.empId,
            empcode:empdata,
            password:password
        };
        // console.warn(data);
        // const res = await fetch('/emppasswordupdate',{
        //     method:"PUT",
        //     headers: {
        //         "Accept":"application/json",
        //         "Content-Type": "application/json",
        //         "Access-Control-Allow-Origin": "*",
        //         },
        //     body:JSON.stringify(data)
        // })
        // .then((response) =>{
        //     console.log("response", response)
        //     if(response.state == 200)
        //     {
        //     alert("Success");
        //     }
        // }).catch(e => {
        //     console.log("e",e);
        // })
        if(!password)
        {
            toast.error("Please Fill the Password.", { position: "top-center" });
        }
        else{

        axios.post('/user/emppasswordupdate', data)        
        .then(res => {
            if(res.data.Status === "Wrong_empid")
        {
        toast.error("There is some Error Connect to IT-Administrator.", { position: "top-center" });
        }
           else if(res.data.Status === "Success")
        {
            setPassword('');
            toast.success("Password Reset Successfully", { position: "top-center" });
        }else{
            toast.error("Data already Exist", { position: "top-center" });            
        }
    })
        .then(err => console.log(err));
    }
        
    }


    const empdataHomePage = async () => {
        try {
            const res = await fetch('/user/empmasterdata');
            const empdata = await res.json();        
            setUserData(empdata); 
        } catch (err) {
            console.log(err);
            
        }
    
    }

    const pageload = () => {

        axios.get('/user/loginauth')    
          .then(res => {
            if(res.data.Status === "Success")
          {                 
            setEmpdata(res.data.empcode);            
      
          }
          else
          {     
            navigate('/Login');
          }
        }).then(err => console.log(err));
      
      }

      useEffect(() => {
        pageload();
        empdataHomePage();

      }, [])

    return(
        <>

<div className="">
    <Navbar /> 
    
    </div>
            <div className='d-flex justify-content-center align-items-center bg-white vh-100'>
    
    <div className='bg-secondary p-3 rounded w-25'>
    <div className='mb-3 text-bg-success text-center form-control-sm rounded-3'>
    <h3>Change Password</h3>
    <h7>{userData.loginid}</h7>   </div>
        <form onSubmit={handleResetPassword}>
        <div className='mb-3'>
            <label htmlFor="empcode"><strong>Employee Code</strong></label>
            <input type="text" placeholder="empcode" disabled value={empdata} className='form-control rounded-0'/>
            
        </div>
        <div className='mb-3'>
            <label htmlFor="password"><strong>New Password</strong></label>
            <input type="text" placeholder="Enter Password" name='password' value={password} onChange={(e) => {setPassword(e.target.value)}} className='form-control rounded-0'/>
            
        </div>        
        
        <button type='submit' className='btn btn-primary w-100 rounded-1' onClick={handleResetPassword}>Reset Password</button>
        </form>
    </div>
</div>
        </>
    )
};

export default Changepassword;