import React from "react";
import Login from "./Login";
import 'bootstrap/dist/css/bootstrap.css';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Routes, Route} from 'react-router-dom';
import Home from "./Home";
import Signup from "./Signup";
import Password from "./components/Password";
import Reportstillmonth from "./components/Reportstillmonth";
import Errorpage from "./components/Errorpage";
import Tcsreport from "./components/Tcsreport";
import Forgotpassword from "./components/Forgotpassword";
import Tcsinventory from "./components/Tcsinventory";
import Tcssoreport from "./components/Tcssoreport";
import Tcssaleshisreport from "./components/Tcssaleshisreport";









const App =() => {
  
  return (
    <>
    
    <Routes>      
      <Route exact path='/' element={<Login />}/>
      <Route exact path='/Login' element={<Login />}/>
      <Route exact path='/Home' element={<Home />}/>
      <Route exact path='/Signup' element={<Signup />}/>
      <Route exact path='/Password' element={<Password />}/>
      <Route exact path='/Reportstillmonth' element={<Reportstillmonth />}/>
      <Route exact path='/Tcsreport' element={<Tcsreport />}/>
      <Route exact path='/Tcssoreport' element={<Tcssoreport />}/>
      <Route exact path='/Tcssaleshisreport' element={<Tcssaleshisreport />}/>      
      <Route exact path='/Tcsinventory' element={<Tcsinventory />}/>
      <Route exact path='/Forgotpassword' element={<Forgotpassword />}/>
      <Route path="*" element={<Errorpage />} />
    </Routes>    
    
    <ToastContainer className="foo w-75" />
    </>
  );
}

export default App;
