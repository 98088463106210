import React, {useEffect, useState} from 'react';
import FrontImage from './Images/passwordimg.jpg';
import RefreshImage from './Images/refresh.png';
import  password from './Generatepass';
import axios from 'axios';
import { toast } from 'react-toastify';


const Forgotpassword = () => {
  let p = new password();
  const ramdomString = Math.random().toString(36).slice(8);
  const[fpassword, setFpassword] = useState(ramdomString);
  const[flogin, setFlogin] = useState('');
  const[captcha, setCaptcha] = useState('');
  const[userData, setUserData] = useState({});
  const[gpbutton, setGpbutton] = useState('');
  const[mailbutton, setMailbutton] = useState('none');


  const forgotvalues = {
    forgotloginid:flogin,
    forgotpassword:'',

};

  const mailvalues ={
    to:userData.emailid,
    userid:flogin,
    subject:'Password Reset',
    mailname:userData.empName,
    description:'Reset Password',
    resetpassword:'',
};
  
  const handleRefresh = (e) => {
    e.preventDefault();    
    setFpassword(Math.random().toString(36).slice(8));
  };

  const handleGenpass = (e) => {
    try {       
      e.preventDefault();      
      if(!flogin  || !fpassword || !captcha)
      {
        alert("Please fill userid & Captcha Details");      
      }
      else if(fpassword !== captcha)
      {
        alert("Please check captcha details not match");
      }
     else if(!mailvalues.to || !mailvalues.mailname || !mailvalues.userid)
      {
          toast.error("Please Generate the Password First!.", { position: "top-center" });
      }
      else
      { 
      const tactualp = p.generatePassword(12);
      forgotvalues.forgotpassword = tactualp;           
      axios.post('/user/forgotpassword', forgotvalues)
      .then(res => {
          if(!forgotvalues.forgotloginid || !flogin)
          {
              toast.error("Please Fill the qrs user id.", { position: "top-center" });
          }
          else if(res.data.Status === "Wrong_empid")
      {
      toast.error("There is some Error Connect to IT-Administrator.", { position: "top-center" });
      }
         else if(res.data.Status === "Success")
      {            
          // alert(forgotvalues.forgotpassword);
          mailvalues.resetpassword = tactualp;            
          axios.post('/mail/sendemail', mailvalues)
          .then(res => {
              if(res.data.Status === "Success")
              {
                  toast.success("Password Reset send on mail Successfully", { position: "top-center" });
                  setFlogin('');
                  setUserData('');
                  setFpassword(ramdomString);
                  setCaptcha('');
                  setMailbutton('none');
                  setGpbutton('');                  
              }
          }). then (err => console.log(err));
          
      }else{            
          toast.error("Data already Exist", { position: "top-center" });            
      }
  })
      .then(err => console.log(err));
      }
  } catch (err) {
      console.log(err);                
  }

  };


  const empdataHomePage = async (e) => {
    e.preventDefault();
    try {
        const userid = flogin;
        if(!flogin  || !fpassword || !captcha)
          {
            alert("Please fill userid & Captcha Details");            
          }
          else if(fpassword !== captcha)
          {
            alert("Please check captcha details not match");
          }
        else
        {
        const res = await fetch('/user/empdataforgotpass/'+userid, {
            method: "GET",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
            },
            credentials: "include"
        });
        if(res.status === 404)
        {
            alert("Please Connect with IT-Administrator User not Exist");
        }
        else
        {
        const forgotempdata = await res.json();        
        setUserData(forgotempdata[0]);
        setGpbutton('none');
        setMailbutton('');
        }
    }
    } catch (err) {
        console.log(err);
    }        
}
  

  useEffect(() => {    
    
  },[]);
  return (
    <>
<section className="vh-100" style={{backgroundColor: '#508bfc'}}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5">
        <div className="card shadow-2-strong" style={{borderRadius: '1rem'}}>
          <div className="card-body p-5 text-center">
          <div className='mb-3'>
            <span className='ms-2'><img src={FrontImage} id="icon" alt="User Icon" /></span>
            <h1 className='ms-2'>Reset Password</h1>
          </div>

            
          <form>
            <div className="form-outline mb-2">
              <input type="email" id="typeEmailX-2" placeholder="Enter User Id" value={flogin} onChange={(e) => {setFlogin(e.target.value)}} className="form-control form-control-sm" />              
            </div>

            <div className="form-check-inline mb-2">
            <div style={{userSelect:"none", color:"#fff", textDecoration:"line-through", background:"black", borderRadius:"5px", width:"100px", height:"30px", fontSize:"22px", float:"left"}} id="typecaptchalX-2" >{fpassword}</div>
            <span style={{marginLeft:"50px"}}><img src={RefreshImage} onClick={handleRefresh}/></span>              
            </div>

            <div className="form-outline mb-2">
              <input type="text" id="typefillcaptcha-2" placeholder="Type the Captcha Details as shown in image" value={captcha} onChange={(e) => {setCaptcha(e.target.value)}} className="form-control form-control-sm" />              
            </div>

            <button className="btn btn-primary btn-lg btn-block" style={{display:gpbutton, fontSize:"12px"}} type="submit" onClick={empdataHomePage}>Generate Password</button>
            <button className="btn btn-primary btn-lg btn-block" style={{display:mailbutton, fontSize:"12px"}} type="submit" onClick={handleGenpass}>Password Send on Email</button>
            <a className='p-3' href="/Login"><span>Back to Login</span></a>
            </form>            
            
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  )
}

export default Forgotpassword