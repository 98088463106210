// import React from 'react'

function Validation(values) {
  let error = {}
    
    if(values.empcode === "")
    {
        error.empcode = "Employee Code should not be empty."
    }
    if(values.loginid === "")
    {
        error.loginid = "Employee Code should not be empty."
    }
    if(values.password === "")
    {
        error.password = "Employee Code should not be empty."
    }
    
}

export default Validation;

