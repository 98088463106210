class password {
    constructor(){
        this.pass ="";

    }    
    generatePassword(len){
        let chars = "abcdefghijklmnopqrstuvwxyz"
        let numbers ="1234567890"
        let special = "!@#$%^&*()"
        if(len<3)
        {
            alert("Your Password should be minimum 3 characters long");
        }
        else{
            let i=0;
            while(i<len)
            {
                this.pass += chars[Math.floor(Math.random() * chars.length)];
                this.pass += numbers[Math.floor(Math.random() * numbers.length)];
                this.pass += special[Math.floor(Math.random() * special.length)];
                i += 3;
            }
            this.pass = this.pass.substring(0, len);
            return this.pass;
        }
    }
}

// generatemaildetail(len)
// let p = new password();
// alert(p.generatePassword(8));
// export{generatePassword};
export default password;