import React, {useState, useEffect} from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Leveldata from './components/Leveldata';
// import { toast } from 'react-toastify';






function Home() {  
  window.history.pushState({id:1}, null, "?q=1234&u=beware");
  // const [empcode, setEmpcode ] = useState('');  
  const [userData, setUserData] = useState({});  
  const [dbddata, SetDbddata] = useState({});
  
  
  
  const navigate = useNavigate();
  // axios.defaults.withCredentials = true;
  
  
  const empdataHomePage = async () => {
    try {
        const res = await fetch('/user/empmasterdata');
        const empdata = await res.json();        
        setUserData(empdata); 
    } catch (err) {
        console.log(err);
        
    }

}

const currentdatadate = async () => {
  try {
    const dbres = await fetch('/homedb/dashboardata');
    const dbresdata = await dbres.json();
    SetDbddata(dbresdata);
    
  } catch (error) {
    console.log(error);
  }
}


const pageload = async() => {

  await axios.get('/user/loginauth')    
    .then(res => {
      if(res.data.Status === "Success")
    {      
      empdataHomePage();
      currentdatadate();      
      // setEmpcode(res.data.empcode);
      // alert(empcode.empcode);
      // toast.success("Login Successfully", { position: "top-center" });
    }
    else
    {     
      navigate('/Login');
    }
  }).then(err => console.log(err));

}

  useEffect (() => {
    pageload();  
}, [])
    
  return (
    <>
    <div className="">
    <Navbar /> 
    
    </div>
    
    <div className='d-flex justify-content-center align-items-top bg-white vh-100'>
    
    <div className='mt-3 w-100'>
    <div className='bg-white p-3 rounded w-100'>    
    {/* Collapse div */}
  <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      <span className=' text-bg-warning rounded-2 form-control-sm font-monospace'>Hi, Dear {userData.empName}, Related Profile Details Information Please click here! .</span>
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
    {/* Collapse div */}
    <div id="collapseExample">
              <div className="card card-body bg-success-subtle">
                <div className="container" style={{ fontSize: "13px" }}>
                  <div className="row align-items-start" style={{ marginBottom: "5px" }}>
                    <div className="col">
                      <div className="label">Employee Code: </div>
                      <input type="text" disabled value={userData.empCode} />
                    </div>
                    <div className="col">
                      <div className="label">Employee Name: </div>
                      <input type="text" disabled value={userData.empName} />
                    </div>
                    <div className="col">
                      <div className="label">Designation: </div>
                      <input type="text" disabled value={userData.designation} />
                    </div>
                    <div className="col">
                      <div className="label">Email id: </div>
                      <input type="text" disabled value={userData.emailid} />
                    </div>
                    <div className="col">
                      <div className="label">Mobile Number: </div>
                      <input type="text" disabled value={userData.mobileNumber} />
                    </div>
                    </div>
                  </div>


                  <div className="container" style={{ fontSize: "13px" }}>
                  <div className="row align-items-start" style={{ marginBottom: "10px" }}>
                    <div className="col">
                      <div className="label">HQ Code: </div>
                      <input type="text" disabled value={userData.hqCode} />
                    </div>
                    <div className="col">
                      <div className="label">HQ Name: </div>
                      <input type="text" disabled value={userData.hqName} />
                    </div>
                    <div className="col">
                      <div className="label">Division: </div>
                      <input type="text" disabled value={userData.division} />
                    </div>
                    <div className="col">
                      <div className="label">Reporting To: </div>
                      <input type="text" disabled value={userData.reportingEmpName} />
                    </div>
                    <div className="col">
                      <div className="label">Next Reporting: </div>
                      <input type="text" disabled value={userData.nextEmpName} />
                    </div>
                    </div>
                  </div>
                </div>               
       {/* Collapse div */}         
      </div>
      </div>
      </div>
      </div>
      </div>
{/* Collapse div */}
            <div className='d-flex flex-column align-items-center'>
              <h6>HQ Wise For Month Sales Details:- {dbddata.dateformonth}.</h6>    
              <Leveldata />
              </div>    
      
        </div>
        
        </div>
        

    </div>
    
    
    </>
    
  )
}

export default Home