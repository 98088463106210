import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {toast } from 'react-toastify';
import { useAsyncError, useNavigate } from 'react-router-dom';
import Validation from './SignupValidation';
import Navbar from './Navbar';
import  password from './components/Generatepass';
// import employeemaster from './components/Empmaster';





function Signup() {
    
    let p = new password();
    // let empclassdata = new employeemaster();
    // const clsempdata = empclassdata.generateemprecord("OPO0378");
    // alert(clsempdata);
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    const [values, setValues] = useState({
        
        empcode:'',
        loginid:'',
        password:''
    })

    const[userData, setUserData] = useState({});
    const[flogin, setFlogin] = useState('');
    const[fpassword, setFpassword] = useState('');    
    const forgotvalues = {
        forgotloginid:flogin,
        forgotpassword:'',
    };
    const mailvalues ={
        to:userData.emailid+","+userData.emergencyEmail,
        userid:flogin,
        subject:'Password Reset',
        mailname:userData.empName,
        description:'Reset Password',
        resetpassword:'',
    };
    
     
    const [errors, setErrors] = useState({})
    const navigate = useNavigate();
    const [empdata, setEmpdata] = useState('');
    


    const  handlerandampass= (e)=>{
        try {       
        e.preventDefault();        
        if(!mailvalues.to || !mailvalues.mailname || !mailvalues.userid)
        {
            toast.error("Please Generate the Password First!.", { position: "top-center" });
        }
        else
        {        
        const t = p.generatePassword(8);
        forgotvalues.forgotpassword = t;
        axios.post('/user/forgotpassword', forgotvalues)
        .then(res => {
            if(!forgotvalues.forgotloginid || !flogin)
            {
                toast.error("Please Fill the qrs user id.", { position: "top-center" });
            }
            else if(res.data.Status === "Wrong_empid")
        {
        toast.error("There is some Error Connect to IT-Administrator.", { position: "top-center" });
        }
           else if(res.data.Status === "Success")
        {            
            // alert(forgotvalues.forgotpassword);
            mailvalues.resetpassword = t;            
            axios.post('/mail/sendemail', mailvalues)
            .then(res => {
                if(res.data.Status === "Success")
                {
                    toast.success("Password Reset send on mail Successfully", { position: "top-center" });
                    setFlogin('');
                    setUserData('');                   
                }
            }). then (err => console.log(err));
            
        }else{            
            toast.error("Data already Exist", { position: "top-center" });            
        }
    })
        .then(err => console.log(err));
        }
    } catch (err) {
        console.log(err);                
    }

    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(Validation(values));
        await axios.post('/user/register', values)
        .then(res => {if(res.data.Status === "Success")
        {
            navigate('/Home');
        }else{
            alert("Data already Exist");
        }
    })
        .then(err => console.log(err));
        

    }

    const handleUpdate = async(event) => {
        try {
           
        event.preventDefault();
       await axios.post('/user/passwordupdate', values)
        .then(res => {
            if(!values.empcode)
            {
                toast.error("Please Fill the Employee Code.", { position: "top-center" });
            }
            else if(res.data.Status === "Wrong_empid")
        {
        toast.error("There is some Error Connect to IT-Administrator.", { position: "top-center" });
        }
           else if(res.data.Status === "Success")
        {
            
            toast.success("Password Reset Successfully", { position: "top-center" });
        }else{
            toast.error("Data already Exist", { position: "top-center" });            
        }
    })
        .then(err => console.log(err));
        
         
    } catch (err) {
        console.log(err);
    }

    };

    const PageAuth = async() => {            
            try {
                // console.warn(pagevalues);
            const pageid = "signupPage"
            await axios.get('/user/pageaccessdata/'+ pageid)
                .then(res => {                    
            if (res.data.Status === "NoAccessPage")
            {
                navigate('/Home');
                toast.error("You don't have access this page.", { position: "top-center" });
            }
            else
            {
                
            }

                })
                .then(err => console.log(err))               
                
            } catch (err) {
                console.log(err);                
            }        
        };

        const loginAuthorized = async() => {
            try {
             
            await axios.get('/user/loginauth')    
              .then(res => {
                if(res.data.Status === "Success")
              {                 
                setEmpdata(res.data.empcode);          
              }
              else
              {     
                navigate('/Login');
              }
            }).then(err => console.log(err));
               
        } catch (err) {
               console.log(err);
        }
          
          };


          const empdataHomePage = async (e) => {
            e.preventDefault();
            try {
                const userid = flogin;
                if(!flogin)
                {
                    alert("Please Fill the user Id");
                }
                else
                {
                const res = await fetch('/user/empmasterdataforgotpass/'+userid, {
                    method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"
                });
                if(res.status === 404)
                {
                    alert("Please Connect with IT-Administrator User not Exist");
                }
                else
                {
                const forgotempdata = await res.json();                
                // console.log(forgotempdata);
                setUserData(forgotempdata[0]);
                
                }
            }
            } catch (err) {
                console.log(err);
            }        
        }
    

        useEffect (() => {            
            loginAuthorized();
            PageAuth();
            
            
            
        }, []);


       
  return (
    <>  
    <Navbar />  
    <div className='d-flex justify-content-center align-items-center bg-white vh-100'>
    
        <div className='bg-secondary p-3 rounded w-30'>
        <div style={{textAlign:"center", fontSize:"30px"}}>Admin Panel For Password Reset</div>        
            <form>
            <div className='mb-3'>
                <label htmlFor="empcode"><strong>Employee Code</strong></label>
                <input type="text" placeholder="Enter Employee Code" onChange={e => setValues({...values, empcode: e.target.value})} className='form-control rounded-0'/>
                {errors.email && <span className='text-danger'>{errors.empcode}</span>}
            </div>
            <div className='mb-3'>
                <label htmlFor="loginid"><strong>User Id</strong></label>
                <input type="email" placeholder="Enter User Id" onChange={e => setValues({...values, loginid: e.target.value})} className='form-control rounded-0'/>
                {errors.loginid && <span className='text-danger'>{errors.loginid}</span>}
            </div>
            <div className='mb-3'>
                <label htmlFor="password"><strong>Password</strong></label>
                <input type="password" placeholder="Enter Password" onChange={e => setValues({...values, password: e.target.value})} className='form-control rounded-0' />
                {errors.password && <span className='text-danger'>{errors.password}</span>}
            </div>
            {/* <button className='btn btn-success w-100 rounded-0' onClick={handleSubmit}>Sign Up</button> */}
            <button type='submit' className='btn btn-primary w-100 rounded-0' onClick={handleUpdate}>Update</button>
            <div className='mb-3'>
                <label htmlFor="notification"><strong>Password Reset and send notification</strong></label>
                <input type="text" placeholder="Enter User Id" value={flogin} onChange={(e) => {setFlogin(e.target.value)}}  className='form-control rounded-0'/>
                {errors.email && <span className='text-danger'>{errors.empcode}</span>}
            </div>
            <button className='btn btn-success w-100 rounded-0' onClick={empdataHomePage}>Generate Password</button>
            <button className='btn btn-success w-100 rounded-0' onClick={handlerandampass}>Send Email</button>
            </form>
        </div>
    </div>
    </>
  )
}

export default Signup