import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import 'react-data-table-component-extensions/dist/utilities';
import {CSVLink} from 'react-csv';
import { ExportToExcelLive } from '../Excelfile';
import Navbar from '../Navbar';
import { toast } from 'react-toastify';


const Tcssaleshisreport = () => {
    
    const navigate = useNavigate();

    
    const [userData, setUserData] = useState({});    
    const [filtereddata, setFiltereddata] = useState([]);
    const [tcsdbdata, setTcsdbdata] = useState({});
    const [values, setValues] = useState([]);
    const [valuessite, setValuessite] = useState([]);
    const [usermapData, setUsermapData] = useState({});
    const [userdivmapData, setUserdivmapData] = useState({});
    const [btnsitestate, setBtnsitestate] = useState('');
    const [btnrefstate, setBtnrefstate] = useState('none');
    const [btnstate, setBtnstate] = useState('none');
    const [hqbtnstate, setHqbtnstate] = useState('none');
    const [btnreport, setBtnreport] = useState('none');
    const senempmapdata = userData.empCode;
    const sendivempmapdata = userData.empCode;
    const [datefromValue, setDatefromValue] = useState(null);
    const [partyValue, setPartyValue] = useState("");
    const [datetoValue, setDatetoValue] = useState(null);
    const [ffdata, setFfdata] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectinvsitedata, setSelectinvsitedata] = useState();
    
    
    

    const tcsfromDate = datefromValue;
    const tcstoDate = datetoValue;

    
    let finalsitemapdata = selectinvsitedata;    
    let finaldivmapdata = '';
    
    for(let i=0; i < userdivmapData.length; i++)
    {
        finaldivmapdata = finaldivmapdata + userdivmapData[i].divisionCode + ",";
    }

    const empsinglesitemapdata = async () => {
        
        try {
            if(!senempmapdata)
            {
                toast.error("Please Connect to QRS-Administration.", { position: "top-center" });
            }
            else
            {
            const res = await fetch('/tcsdb/sitemapdata/'+senempmapdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            const empsitemapdata = await res.json();
            if(res.status === 404)
            {
                toast.error("You have Zero Data Available.", { position: "top-center" });
            }
            else
            {            
            setValuessite(empsitemapdata);            
            }
        }
        } catch (err) {
            console.log(err);
            
        }

    }
    

    const empdivemapdata = async () => {
        
        try {
            if(!sendivempmapdata || !datefromValue || !datetoValue)
            {
                toast.error("Please Select the Date Filter or Connect to QRS-Administration.", { position: "top-center" });
            }
            else if(datefromValue > datetoValue)
            {
              toast.error("Please select the Correct Date Range.", { position: "top-center" });
            }
            else if((Date.parse(datetoValue)-Date.parse(datefromValue))/86400000 > 185)
            {
              toast.error("Please select the below 6 Months data.", { position: "top-center" });
            }
            else
            {
              
            const res = await fetch('/tcsdb/divemapdata/'+sendivempmapdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            const empmapdata = await res.json();
            if(res.status === 404)
            {
                toast.error("You have Zero Data Available.", { position: "top-center" });
            }
            else
            {
            setUserdivmapData(empmapdata);
            setBtnsitestate('none');            
            setBtnrefstate('');
            setBtnreport('');
            // console.log(empmapdata);
            // console.log(finalsitemapdata);
            }
        }
        } catch (err) {
            console.log(err);
            
        }

    }

    // const empsitemapdata = async () => {
        
    //     try {
    //         if(!senempmapdata)
    //         {
    //             toast.error("Please Connect to QRS-Administration.", { position: "top-center" });
    //         }
    //         else
    //         {
    //         const res = await fetch('/tcsdb/sitemapdata/'+senempmapdata, {
    //             method: "GET",
    //                 headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json"
    //                 },
    //                 credentials: "include"  
    //         });
    //         const empmapdata = await res.json();
    //         if(res.status === 404)
    //         {
    //             toast.error("You have Zero Data Available.", { position: "top-center" });
    //         }
    //         else
    //         {
    //         setUsermapData(empmapdata);
            
    //         setBtnrefstate('none');
    //         setBtnreport('');
    //         // console.log(empmapdata);
    //         // console.log(finalsitemapdata);
    //         }
    //     }
    //     } catch (err) {
    //         console.log(err);
            
    //     }

    // }


    const empdataHomePage = async () => {
        try {
            const res = await fetch('/user/empmasterdata');
            const empdata = await res.json();        
            setUserData(empdata); 
            // console.log(empdata);
        } catch (err) {
            console.log(err);
            
        }
    
    }    

    // const currentdatadate = async () => {
    //     try {
    //       const Tcsdbres = await fetch('/homedb/dashboardata');
    //       const tcsdbresdata = await Tcsdbres.json();
    //     //   console.log(tcsdbresdata);
    //       SetTcsddata(tcsdbresdata);
        
          
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }


      const loginAuthorized = async () => {

        await axios.get('/user/loginauth')    
          .then(res => {
            if(res.data.Status === "Success")
          {      
            empdataHomePage();
            // currentdatadate();
            // empsitemapdata();
          }
          else
          {     
            navigate('/Login');
          }
        }).then(err => console.log(err));
      
      }

      const handlepartycode = async(e) => {        
        const partycodeValue = e.target.value;        
        // console.log("from:", datefValue);
        setPartyValue(partycodeValue);       
        // console.log("from:", datefValue);        
      }

      const handlefromDateUpdate = async(e) => {        
        const datefValue = e.target.value;        
        // console.log("from:", datefValue);
        setDatefromValue(datefValue);       
        // console.log("from:", datefValue);        
      }

      const handletoDateUpdate = async(e) => {        
        const datetValue = e.target.value;        
        // console.log("to:", datetValue);        
        setDatetoValue(datetValue);       
        // console.log("to:", datetValue);
      }

      const hqcodelist = async () => {
        try {
            let hqhirdata = userData.hqCode;
            // console.log(hqhirdata);
            const hqres = await fetch('/tcsdb/hirhqmapdata/'+hqhirdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            
            const hqresdata = await hqres.json();
            if(hqresdata.status === 404)
            {
                toast.error("You don't have access of HQ.", { position: "top-center" });
            }
            else
            {
            // console.log(hqresdata);
            setValues(hqresdata);
            }
        
          
        } catch (error) {
          console.log(error);
        }
      }


      const handlehqwisedata = () => {
        
        setHqbtnstate('none');
        if(filtereddata.length <=0)
        {
          toast.error("There are no record to display.", { position: "top-center" });
        }
        else{
        // let myArray = [];
        for(let i =0; i<values.length; i++ )
        {
          let temp = values[i].hqCode;
          // let tempname = values[i].hqName;
          // console.log(values);
          let sval = 0;
          for(let j=0; j<filtereddata.length; j++)
          {
            if(filtereddata[j].HQ_Code === temp)
            sval += filtereddata[j].Amount_after_Discount;
          }
          // myArray[i]=("HQ Code:-" + temp + "HQ Name:" +tempname + "Sale Value:" + Math.round(sval,2));
          // ("HQ Code:-" + temp + "HQ Name:" +tempname + "Sale Value:" + Math.round(sval,2));
          // console.log("HQ Code:-" + temp + "HQ Name:" +tempname + "Sale Value:" + Math.round(sval,2));
          values[i].SalesValue = sval;          
        }
        setHqbtnstate('');
      }
      }



    //   let PTN = 0;
    //   let NUC = 0;
    //   let RES = 0;
    //   let ADF = 0;
    //   let ATM = 0;
    //   let BLP =0;
    //   let LUX = 0;
    //   let CPCdiv = 0;      

    //   for(let i = 0; i < filtereddata.length; i++)
    //   {
    //     if(filtereddata[i].Item_Division === 'OPL-PROTON - OPL-PROTON' || filtereddata[i].Item_Division === 'OPL-CARDIAZONE - OPL-CARDIAZONE' )
    //     {PTN += filtereddata[i].Amount_after_Discount;}
    //     else if(filtereddata[i].Item_Division === 'OPL-NUCLEUS - OPL-NUCLEUS' || filtereddata[i].Item_Division === 'OPL-CEFILABZONE - OPL-CEFILABZONE' )
    //     {NUC += filtereddata[i].Amount_after_Discount;}
    //     else if(filtereddata[i].Item_Division === 'OPL-ADLIFE - OPL-ADLIFE')
    //     {ADF += filtereddata[i].Amount_after_Discount;}
    //     else if(filtereddata[i].Item_Division === 'OPL-BLIP - OPL-BLIP' )
    //     {BLP += filtereddata[i].Amount_after_Discount;}
    //     else if(filtereddata[i].Item_Division === 'OPL-ATOM - OPL-ATOM' )
    //     {ATM += filtereddata[i].Amount_after_Discount;}
    //     else if(filtereddata[i].Item_Division === 'OPL-CPC - OPL-CPC' )
    //     {CPCdiv += filtereddata[i].Amount_after_Discount;}
    //     else if(filtereddata[i].Item_Division === 'OPL-MTO - OPL-MTO' || filtereddata[i].Item_Division === 'OPL-PROFESSIONAL - OPL-PROFESSIONAL' )
    //     {LUX += filtereddata[i].Amount_after_Discount;}
    //     else{
    //       RES += filtereddata[i].Amount_after_Discount;
    //     }
    //   } 


    //   let Proton = Math.round(PTN/100000*100)/100;
    //   let Nucleus = Math.round(NUC/100000*100)/100;
    //   let Adlife = Math.round(ADF/100000*100)/100;
    //   let Blip = Math.round(BLP/100000*100)/100;
    //   let ATOM = Math.round(ATM/100000*100)/100;
    //   let Luxary =Math.round(LUX/100000*100)/100;
    //   let CPC =Math.round(CPCdiv/100000*100)/100;
    //   let rest = Math.round(RES/100000*100)/100;
    //   let TotalValue = Math.round((Proton+Nucleus+Adlife+Blip+ATOM+Luxary+rest)*100)/100;

    const divwisereport = async () => {        
        try {
            let frontsitedata = finalsitemapdata;
            let frontitemDivision = finaldivmapdata;
            let partydata = partyValue;
            if(!senempmapdata || !frontsitedata || !frontitemDivision || !tcsfromDate || !tcstoDate)
            {
                toast.error("Please Select CFA-Connect to QRS-Administration.", { position: "top-center" });
            }
            else if(tcsfromDate > tcstoDate)
            {
              toast.error("Please select the Correct Date Range.", { position: "top-center" });
            }
            else if((Date.parse(datetoValue)-Date.parse(datefromValue))/86400000 > 185)
            {
              toast.error("Please select the below 6 Months data.", { position: "top-center" });
            }
            else
            {
            setBtnreport('true');
            setBtnstate('none');            
            const data = { frontsitedatas:"", frontitemDivisions:"", tcsfromDates:"", tcstoDates:"", partyCodes:"" };

            const res = await fetch('/tcsdb/divwisereports',{
              method: "POST",
              headers:{
                  // "Accept": "application/json",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                frontsitedatas:finalsitemapdata, frontitemDivisions: finaldivmapdata, tcsfromDates:tcsfromDate, tcstoDates: tcstoDate, partyCodes: partydata
              })})
            
            const salesdata = await res.json();
            // const fsalesdata = await salesdata.filter((fdata) => (fdata.HQ_Code !== ""))
            // console.log(fsalesdata);
            // setTcsdbdata(fsalesdata);
            setFiltereddata(salesdata);
            setBtnstate('');
            setBtnreport('');
        } 
        } catch (err) {
            console.log(err);
            
        }
    }
   
    

    const pagauth = async() => {
        const pageids = "tcsuserlivereportPage";
        const res = await fetch('/user/pageaccessdatas/'+ pageids, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
      
        // const expdata = await res.json();  
        if(res.status === 404)
        {
          navigate('/Home');
          toast.error("You don't have access this page.", { position: "top-center" });
        }
      }

      const refreshdata = async() => {

        const res = await fetch('/tcsdb/refreshpardata', {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        })

      }


     


      const customStyles = {
        headRow: {
          style:{
            backgroundColor:'purple',
            color:'white',            
            height:80,                  
          },    
        },
        headCells:{
          style:{
            fontSize:'14px',
            
          }
        }
      }
      
        const columns = [
          {
              name: "Sales_Invoice_No/Sales_Return_No",
              selector: "Sales_Invoice_No/Sales_Return_No",
                            
            },
          {
            name: "Sales_Return_Type",
            selector: "Sales_Return_Type",
            // sortable:true,
            
          },
          {
            name: "Sales_Invoice_Date/Sales_Return_Date",
            selector: "Sales_Invoice_Date/Sales_Return_Date",
          },
          {
            name: "Party_Code",
            selector: "Party_Code",      
          },
          {
            name: "Party_Name",
            selector: "Party_Name",
            width: "240px",
            wrap:true,
          },
          {
            name: "Party_Wise_City",
            selector: "Party_Wise_City",
            sortable:true,
          },
          {
            name: "Party_Wise_State",
            selector: "Party_Wise_State",
          },
          {
            name: "Item_Code",
            selector: "Item_Code",
          },
          {
            name: "Alternate_S/W_Code",
            selector: "Alternate_S/W_Code",
          },
          {
            name: "Item_Name",
            selector: "Item_Name",
            width: "240px",
            wrap:true,
          },
          {
            name: "HQ_Code",
            selector: "HQ_Code",
          },
          {
            name: "HQ_Name",
            selector: "HQ_Name",
          },
          {
            name: "Item_Division",
            selector: "Item_Division",
          },
          {
            name: "Site",
            selector: "Site",
          },
          {
            name: "Bill_Qty",
            selector: "Bill_Qty",
          },
          {
            name: "Free_Qty",
            selector: "Free_Qty",
          },          
          {
            name: "Amount_after_Discount",            
            selector: "Amount_after_Discount",            
          },
        ];

       



    useEffect (() => {
        pagauth();
        loginAuthorized();
        
        
      }, []);

  return (
    <>
    <div className="">
    <Navbar />   
    </div>
    <div className='d-flex justify-content-center align-items-top bg-white vh-100'>
    <div className='mt-3 w-100'>
    <div className='bg-white p-3 rounded w-100'>
    <div className='bg-info w-50 rounded-1 mt-1'>Division Wise Sales History Report ZSM and Above:</div>
    <button className='btn btn-primary rounded-1 ms-5 mb-2' onClick={empsinglesitemapdata}>1. Site Details Click here!</button>    
    <select className='ms-5 w-25' onChange={(e)=> setSelectinvsitedata(e.target.value)} >
    <option defaultValue={true}>--Select--</option>
    {
        valuessite.map((opt,i)=><option key={i}>{opt.siteCode}</option>)
    }   
    </select>
    <span className='ms-2'>Party Code: </span><input className='w-25' type='number' onChange={(e)=> handlepartycode(e)}></input>
    <div className='d-flex mt-2 flex-column align-items-center'>
    <div className='mb-2'> <label style={{textAlign:"right", width:"100px"}}>From Date:-</label>    
    <input type="date" id="fromdate" onChange={(e) => handlefromDateUpdate(e)} />
    <label style={{textAlign:"right", width:"100px"}}>To Date:-</label>    
    <input style={{marginRight:"50px"}} type="date" id="todate" onChange={(e) => handletoDateUpdate(e)} />    
    </div>        
    <div>
    <button className='btn btn-primary rounded-1' style={{display:btnsitestate}} onClick={empdivemapdata}>2. Select Division Data Details</button>
    {/* <button className='btn btn-primary rounded-1' style={{display:btnrefstate}} onClick={empsitemapdata}>2. Select Site Data Details</button> */}
    <button className='btn btn-primary rounded-1' style={{display:btnreport}} disabled={btnreport} onClick={divwisereport}>3. Refresh Division Wise Sales Report</button>
    
    <button className='btn btn-primary rounded-1 ms-1' onClick={hqcodelist}>4. Select HQ Wise Data</button>
    <button className='btn btn-primary rounded-1 ms-2' onClick={handlehqwisedata}>5. Generate HQ/Rs. Wise Pivot</button>
    <span className='fs-6 ms-2' style={{display:hqbtnstate}}><ExportToExcelLive reportLiveDetails={values} /></span>
    </div>
            <div><span style={{fontSize:"12px"}}>Division:- {finaldivmapdata}</span></div>
            {/* <div><span style={{fontSize:"13px", backgroundColor:"yellow", fontWeight:"bold"}}>Final Sales Figures in Lacs:- Proton : {Proton} || Nucleus : {Nucleus} || Adlife : {Adlife} || Blip : {Blip} || ATOM : {ATOM} || Luxary : {Luxary} || CPC : {CPC} || Rest Value : {rest} || Total Value : {TotalValue}</span></div> */}
            {/* <span className='fs-6' style={{display:btnstate}}><ExportToExcelLive reportLiveDetails={tcsdbdata} /></span> */}
            
                

    {/* <div>
    <ExportToExcel reportDetails={filtereddata} />
    </div> */}
  <DataTableExtensions
  columns={columns}
  data={filtereddata}
  exportHeaders={true}
  print={false}
  fileName={"SalesReport"}
  
  
  >
  
    <DataTable
    // title="HQ Wise For Month Sales Details:"
    // columns={columns}
    // data={filtereddata}
    customStyles={customStyles}
    pagination
    fixedHeader
    fixedHeaderScrollHeight="450px"
    
    
    
    
    
    
    // selectableRows
    // onSelectedRowsChange={handleChange}
    // selectableRowsHighlight
    
    // actions={<CSVLink data={filtereddata} filename='TMPerformanceReports' onClick={() => {}} className="btn btn-sm btn-info">Performance Data Export in Excel</CSVLink>}    
    // subHeader
    // subHeaderComponent={
    //   <input type='text' placeholder='Search here' className='w-25 form-control'
    //   value={search} onChange={(e) => setSearch(e.target.value.replace(/[^a-z^ -]/gi, ''))}
    //    />
    // }
    // subHeaderAlign='center' 
    
    
     /> 
     </DataTableExtensions>
    </div>    
            </div>
        </div>
    </div>
    </>
  )
}

export default Tcssaleshisreport