import React, {useState} from 'react';
import FrontImage from '../src/components/Images/FrontImage.png';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';



function Login() {
  const d = new Date();
  const ServerDatedata = d.getDate() +"-"+(d.getMonth()+1)+"-"+d.getFullYear()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
  
  const [values, setValues] = useState({
    loginid: '',
    password: '',
    lldate:ServerDatedata
  })



  const navigate = useNavigate();
  // axios.defaults.withCredentials = true;
  
  const handleSubmit=(e) => {
    e.preventDefault();
    if(!values.loginid || !values.password)
    {
      return toast.error(("Please Fill Login id and Password"), { position: "top-center" });
    }
    axios.post('/user/login', values)
    .then(res => {
      if(res.data.Status === "Success")
      {
        navigate ('/Home');
        toast.success(("Login Successfully"), { position: "top-center" });
      }
      else{        
        toast.error(res.data.Error, { position: "top-center" });
      }
    })
    .then(err => console.log(err));
    
  }

  return (
    <>
<section className="vh-100" style={{backgroundColor: '#508bfc'}}>
{/* style={{ backgroundImage:`url(${BackImage})`, backgroundRepeat:"no-repeat" }} */}
  <div className="container py-1 h-100">
    <div className="col d-flex justify-content-center align-items-center h-100">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5">
        <div className="card shadow-2-strong" style={{borderRadius: '1rem'}}>
          <div className="card-body p-5 text-center">
          <div className='mb-3'>
            <span className='ms-2'><img src={FrontImage} id="icon" alt="User Icon" /></span>
            <h1 className='ms-2'>Ozone Group</h1>
          </div>

            
          <form onSubmit={handleSubmit}>
            <div className="form-outline mb-4">
              <input type="email" id="typeEmailX-2" placeholder="Enter User Id" onChange={e => setValues({...values, loginid: e.target.value})} className="form-control form-control-lg" />              
            </div>

            <div className="form-outline mb-4">
              <input type="password" id="typePasswordX-2" placeholder="Enter Password" onChange={e => setValues({...values, password: e.target.value})} className="form-control form-control-lg" />
              <a className='p-3' href="/Forgotpassword"><span style={{float:"right", fontSize:"12px"}}>Forgot Password?</span></a>
            </div>
            

            

            <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={handleSubmit}>Login</button>
            </form>
            <hr className="my-4" />
              <div id="formFooter" className='align-items-center w-100'>
            <a className='p-3' href="https://ozonepharma.com" rel="noreferrer" target="_blank"><span className='align-items-center'>Go to the Site</span></a>
          
          </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  )
}

export default Login