import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from 'react-toastify';
import {CSVLink} from 'react-csv';
import { ExportToExcel } from '../Excelfile';
import *   as XLSX from "xlsx";


const Leveldata = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filtereddata, setFiltereddata] = useState([]);
  const [socmdata, setSocmdata] = useState([]);
  const [btnstate, setBtnstate] = useState('none');  
  const [refreshbtnstate, setRefreshbtnstate] = useState('none');
  const [prbtnstate, setPrbtnstate] = useState('');
  const [sohqsearchdata, setSohqsearchdata] = useState([]);



const divisionwisehqdata = async () => {
  try {
    
    const cmsalessres = await fetch('/homedb/socurrentmonthdata');
    const salescmsalessres = await cmsalessres.json();
    

    if(cmsalessres.status === 422 || !salescmsalessres)
    {
      toast.error("There is not data for display", { position: "top-center" });      
    }
    else
    {
      setSocmdata(salescmsalessres);      
      toast.success("Please Click on Division Wise Sales Report Button...", { position: "top-center" });
      setBtnstate('');

    }   
    
  } catch (error) {
    
    console.log(error);
    
  }
};


//-- Export to excel funtion --//

const handleOnExport = async() => {      
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(sohqsearchdata);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  await XLSX.writeFile(wb, "SalesReports.csv");  
}

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

  const handleleveldata = async () => {
    try {
      const salessres = await fetch('/homedb/levelsalesfmdata');
      const salesresdata = await salessres.json();
      if(salessres.status === 422 || !salesresdata)
    {
      toast.error("There is not data for display", { position: "top-center" });
    }
    else
    {
      setData(salesresdata);
      setFiltereddata(salesresdata);
      setRefreshbtnstate('');
      setPrbtnstate('none');
      // console.log(data);

    }      
      
    } catch (error) {
      console.log(error);
      
    }
  };


// const handlesohqdata = () => {
//   const soresult = socmdata.filter((datas => datas.hqCode === sohqsearch));

//   // const soresult = socmdata.filter((data) => {
//   //   return data.hqCode.match('ID0451-L1-(N)');
//   // })
//   setSohqsearchdata(soresult);
//   console.log(soresult);
// }

  let iqmsum =0;
  let sumcursalesum =0;   
  let sumexpirysum =0;
  let sumpvsalesum =0;
  let achivemntprecent = 0;
    for(let i = 0; i < filtereddata.length; i++)
          {
            iqmsum += filtereddata[i].IQM_Amount;
            sumcursalesum += filtereddata[i].Sales_Amount;
            sumexpirysum += filtereddata[i].Expiry_Amount;
            sumpvsalesum += filtereddata[i].Previous_Year_Sales;
            achivemntprecent = ((sumcursalesum / iqmsum)*100).toFixed(2);
          }          
  let cursalesum = Math.round(sumcursalesum,2);
  let expirysum = Math.round(sumexpirysum,2);
  let pvsalesum = Math.round(sumpvsalesum,2);

const customStyles = {
  headRow: {
    style:{
      backgroundColor:'purple',
      color:'white',            
      height:80,                  
    },    
  },
  headCells:{
    style:{
      fontSize:'14px',
      
    }
  }
}

  const columns = [
    {
      name: "Rep's HQ Code",
      selector: (row) => row.SOHQ_Code,
      sortable:true,
      
    },
    {
      name: "Rep's HQ Name",
      selector: (row) => row.SOHQ_Name,
    },
    {
      name: "IQM Amount",
      selector: (row) => row.IQM_Amount,      
    },
    {
      name: "Sales Value",
      selector: (row) => row.Sales_Amount,
    },
    {
      name: "% Achievement Over IQM Value",
      selector: (row) => row.IQMpercent,
      sortable:true,
    },
    {
      name: "Expiry Value",
      selector: (row) => row.Expiry_Amount,
    },
    {
      name: "% Expiry",
      selector: (row) => row.Expirypercent,
    },
    {
      name: "Last Year Sales Value",
      selector: (row) => row.Previous_Year_Sales,
    },
    {
      name: "% Growth Over Last Year Sales",
      selector: (row) => row.GrowthPercent,
      
    },
    {
      name: "Action",      
      cell: (row) => (
        <button className='btn btn-primary'        
        onClick={async() => {
          let dat = row.SOHQ_Code;
          const soresult = socmdata.filter(datas => datas.hqCode === dat);
          setSohqsearchdata(soresult);
          // await delay(10000);
          await handleOnExport();
          }}        
        >Download</button>       
      )
      
    },
  ];

  

  useEffect (() => {
    // handleleveldata();
    
  }, []);

  useEffect (() => {
    const result = data.filter((datas) => {
      return datas.CallingHQCode.toLowerCase().match(search.toLocaleLowerCase());
    });    
    setFiltereddata(result);
  }, [search]);

  return (
  <>
  <div className='bg-primary form-control-lg text-bg-dark center fs-6'>
    <span className='p-3'>IQM_Value:- {iqmsum}</span>
    <span className='p-3'>||Sale_Value:- {cursalesum}</span>
    <span className='p-3'>||% Achivement:- {achivemntprecent}</span>
    <span className='p-3'>||Expiry_Value:- {expirysum}</span>
    <span className='p-3'>||LYSale_Value:- {pvsalesum}</span>
    
    {/* <span className='p-3 btn btn-success'> <CSVLink filename='Division Wise Sales Report' data={socmdata} onClick={() => {}} className="btn btn-sm btn-info">Export in Excel</CSVLink></span> */}
    
  </div>

  <div>
  <button className='mt-2 btn btn-warning fs-6' onClick={handleleveldata} style={{display:prbtnstate}}>Hi, Related Performance Reports. Please Click here!</button>
  <button className='mt-2 btn btn-info fs-6' style={{display:refreshbtnstate}} onClick={divisionwisehqdata}>Refresh Division Wise Sales Reports</button>  
      <span className='fs-6' style={{display:btnstate}}><ExportToExcel reportDetails={socmdata} /></span>      

  </div>
  {/* <div>
    <ExportToExcel reportDetails={filtereddata} />
  </div> */}
  {/* <DataTableExtensions columns={columns} data={filtereddata} exportHeaders={true}
    fileName={"Per_Report"}
    > */}
    <DataTable
    // title="HQ Wise For Month Sales Details:"
    columns={columns}
    data={filtereddata}
    customStyles={customStyles}
    pagination
    fixedHeader
    fixedHeaderScrollHeight="450px"
    
    
    // selectableRows
    // selectableRowsHighlight
    actions={<CSVLink data={filtereddata} filename='FMPerformanceReports' onClick={() => {}} className="btn btn-sm btn-info">Performance Data Export in Excel</CSVLink>}    
    subHeader
    subHeaderComponent={
      <input type='text' placeholder='Search here' className='w-25 form-control'
      value={search} onChange={(e) => setSearch(e.target.value.replace(/[^a-z 0-9-/_,^]/gi, ''))}
       />
    }
    subHeaderAlign='center' 
    
    
     />  
     
     {/* </DataTableExtensions> */}
     
     </>    
  )
}

export default Leveldata