import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
// import 'react-data-table-component-extensions/dist/utilities';
// import {CSVLink} from 'react-csv';
import { ExportToExcelLive } from '../Excelfile';
import Navbar from '../Navbar';
import { toast } from 'react-toastify';


const Tcssoreport = () => {
    
    const navigate = useNavigate();

    
    const [userData, setUserData] = useState({});    
    const [filtereddata, setFiltereddata] = useState([]);
    const [values, setValues] = useState([]);
    const [tcsdbdata, SetTcsdbdata] = useState({});
    const [usermapData, setUsermapData] = useState({});
    const [userdivmapData, setUserdivmapData] = useState({});
    const [selectinvsitedata, setSelectinvsitedata] = useState();
    const [hqhirData, setHqhirData] = useState({});
    const [btnsitestate, setBtnsitestate] = useState('');
    const [btnrefstate, setBtnrefstate] = useState('none');
    const [btnstate, setBtnstate] = useState('none');
    const [hqbtnstate, setHqbtnstate] = useState('none');
    const [btnreport, setBtnreport] = useState('none');
    const [btnfinalreport, setBtnfinalreport] = useState('none');
    const senempmapdata = userData.empCode;
    const sendivempmapdata = userData.empCode;
    const [datefromValue, setDatefromValue] = useState(null);
    const [datetoValue, setDatetoValue] = useState(null);
    const [hqNamedata, setHqNamedata] = useState('');
    const [hqfvalue, setHqvalue] = useState('');   
    
    
    
    

    const tcsfromDate = datefromValue;
    const tcstoDate = datetoValue;

    
    let finalsitemapdata = '';
    
    for(let i=0; i < usermapData.length; i++)
    {
        finalsitemapdata = finalsitemapdata + usermapData[i].siteCode + ",";
    }

    let finaldivmapdata = '';
    
    for(let i=0; i < userdivmapData.length; i++)
    {
        finaldivmapdata = finaldivmapdata + userdivmapData[i].divisionCode + ",";
    }

    

    const empdivemapdata = async () => {
        
        try {
            if(!sendivempmapdata || !datefromValue || !datetoValue)
            {
                toast.error("Please Select the Date Filter or Connect to QRS-Administration.", { position: "top-center" });
            }
            else if(datefromValue > datetoValue)
            {
              toast.error("Please select the Correct Date Range.", { position: "top-center" });
            }
            else if((Date.parse(datetoValue)-Date.parse(datefromValue))/86400000 > 92)
            {
              toast.error("Please select the below 3 Months data.", { position: "top-center" });
            }
            else
            {
              
            const res = await fetch('/tcsdb/divemapdata/'+sendivempmapdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            const empmapdata = await res.json();
            if(res.status === 404)
            {
                toast.error("You have Zero Data Available.", { position: "top-center" });
            }
            else
            {
            setUserdivmapData(empmapdata);
            setBtnsitestate('none');            
            setBtnrefstate('');
            // console.log(empmapdata);
            // console.log(finalsitemapdata);
            }
        }
        } catch (err) {
            console.log(err);
            
        }

    }

    const empsitemapdata = async () => {
        
        try {
            if(!senempmapdata)
            {
                toast.error("Please Connect to QRS-Administration.", { position: "top-center" });
            }
            else
            {
            const res = await fetch('/tcsdb/sitemapdata/'+senempmapdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            const empmapdata = await res.json();
            if(res.status === 404)
            {
                toast.error("You have Zero Data Available.", { position: "top-center" });
            }
            else
            {
            setUsermapData(empmapdata);
            
            setBtnrefstate('none');
            setBtnreport('');
            // console.log(empmapdata);
            // console.log(finalsitemapdata);
            }
        }
        } catch (err) {
            console.log(err);
            
        }

    }


    const empdataHomePage = async () => {
        try {
            const res = await fetch('/user/empmasterdata');
            const empdata = await res.json();        
            setUserData(empdata); 
            // console.log(empdata);
        } catch (err) {
            console.log(err);
            
        }
    
    }  
    
    let tempsval =0;
    for(let i=0; i<tcsdbdata.length; i++)
    {
      if(tcsdbdata[i].HQ_Code === hqfvalue)
      {
        tempsval += tcsdbdata[i].Amount_after_Discount;
      }
    }
    let hqdatasval=Math.round(tempsval*100)/100;

    const hqcodelist = async () => {
        try {
            let hqhirdata = userData.hqCode;
            // console.log(hqhirdata);
            const hqres = await fetch('/tcsdb/hirhqmapdata/'+hqhirdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            
            const hqresdata = await hqres.json();
            if(hqresdata.status === 404)
            {
                toast.error("You don't have access of HQ.", { position: "top-center" });
            }
            else
            {
            // console.log(hqresdata);
            setValues(hqresdata);
            }
        
          
        } catch (error) {
          console.log(error);
        }
      }


      const handlehqwisedata = () => {
        
        setHqbtnstate('none');
        if(tcsdbdata.length <=0)
        {
          toast.error("There are no record to display.", { position: "top-center" });
        }
        else{
        // let myArray = [];
        for(let i =0; i<values.length; i++ )
        {
          let temp = values[i].hqCode;
          // let tempname = values[i].hqName;
          // console.log(values);
          let sval = 0;
          for(let j=0; j<tcsdbdata.length; j++)
          {
            if(tcsdbdata[j].HQ_Code === temp)
            sval += tcsdbdata[j].Amount_after_Discount;
          }
          // myArray[i]=("HQ Code:-" + temp + "HQ Name:" +tempname + "Sale Value:" + Math.round(sval,2));
          // ("HQ Code:-" + temp + "HQ Name:" +tempname + "Sale Value:" + Math.round(sval,2));
          // console.log("HQ Code:-" + temp + "HQ Name:" +tempname + "Sale Value:" + Math.round(sval,2));
          values[i].SalesValue = sval;          
        }
        setHqbtnstate('');
      }
      }


      const loginAuthorized = async () => {

        await axios.get('/user/loginauth')    
          .then(res => {
            if(res.data.Status === "Success")
          {      
            empdataHomePage();
            // currentdatadate();
            // empsitemapdata();
          }
          else
          {     
            navigate('/Login');
          }
        }).then(err => console.log(err));
      
      }

      const handlefromDateUpdate = async(e) => {        
        const datefValue = e.target.value;        
        // console.log("from:", datefValue);
        setDatefromValue(datefValue);       
        // console.log("from:", datefValue);        
      }

      const handletoDateUpdate = async(e) => {        
        const datetValue = e.target.value;        
        // console.log("to:", datetValue);        
        setDatetoValue(datetValue);       
        // console.log("to:", datetValue);
      }

    const divwisereport = async () => {        
        try {
            let frontsitedata = finalsitemapdata;
            let frontitemDivision = finaldivmapdata;            
            if(!senempmapdata || !frontsitedata || !frontitemDivision || !tcsfromDate || !tcstoDate || !hqfvalue || hqfvalue === "--Select--")
            {
                toast.error("Please select above field .", { position: "top-center" });
            }
            else if(tcsfromDate > tcstoDate)
            {
              toast.error("Please select the Correct Date Range.", { position: "top-center" });
            }
            else if((Date.parse(datetoValue)-Date.parse(datefromValue))/86400000 > 92)
            {
              toast.error("Please select the below 3 Months data.", { position: "top-center" });
            }
            else
            {
            setBtnreport('true');
            setBtnstate('none');            
            const data = { frontsitedatas:"", frontitemDivisions:"", tcsfromDates:"", tcstoDates:"", partyCodes:"" };

            const res = await fetch('/tcsdb/divwisereports',{
              method: "POST",
              headers:{
                  // "Accept": "application/json",
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                frontsitedatas:finalsitemapdata, frontitemDivisions: finaldivmapdata, tcsfromDates:tcsfromDate, tcstoDates: tcstoDate, partyCodes:""
              })})
            
            const salesdata = await res.json();
            const fsalesdata = await salesdata.filter((fdata) => (fdata.HQ_Code === hqfvalue))
            // console.log(fsalesdata);
            SetTcsdbdata(salesdata);
            setFiltereddata(fsalesdata);
            setBtnstate('');
            setBtnreport('none');
            setBtnfinalreport('');
        } 
        } catch (err) {
            console.log(err);
            
        }
    }
   
    const handlehqwisereport = async () => {
      setBtnfinalreport('true');
      const finalfsalesdata = await tcsdbdata.filter((fdata) => (fdata.HQ_Code === hqfvalue))
      setFiltereddata(finalfsalesdata);
      setBtnfinalreport('');

    }


    const pagauth = async() => {
        const pageids = "TcsaboveasmsalesPage";
        const res = await fetch('/user/pageaccessdatas/'+ pageids, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
      
        // const expdata = await res.json();  
        if(res.status === 404)
        {
          navigate('/Home');
          toast.error("You don't have access this page.", { position: "top-center" });
        }
      }

      const handlehqlistdata = async(e) => {
        setHqvalue(e.target.value);
        const hqfvaluedata = e.target.value;
        if(hqfvaluedata === "--Select--")
          {
              toast.error("Please Select the HQ Data.", { position: "top-center" });
          } 
          else{
        const hqnamefvalue = values.filter((fdata) => (fdata.hqCode === hqfvaluedata))
        setSelectinvsitedata(hqfvaluedata);
        setHqNamedata(hqnamefvalue[0].hqName);
        // console.log(hqnamefvalue[0].hqName);
          }
      }


      const customStyles = {
        headRow: {
          style:{
            backgroundColor:'purple',
            color:'white',            
            height:80,                  
          },    
        },
        headCells:{
          style:{
            fontSize:'14px',
            
          }
        }
      }
      
        const columns = [
          {
              name: "Sales_Invoice_No/Sales_Return_No",
              selector: "Sales_Invoice_No/Sales_Return_No",
                            
            },
          {
            name: "Sales_Return_Type",
            selector: "Sales_Return_Type",
            // sortable:true,
            
          },
          {
            name: "Sales_Invoice_Date/Sales_Return_Date",
            selector: "Sales_Invoice_Date/Sales_Return_Date",
          },
          {
            name: "Party_Code",
            selector: "Party_Code",      
          },
          {
            name: "Party_Name",
            selector: "Party_Name",
            width: "240px",
            wrap:true,
          },
          {
            name: "Party_Wise_City",
            selector: "Party_Wise_City",
            sortable:true,
          },
          {
            name: "Party_Wise_State",
            selector: "Party_Wise_State",
          },
          {
            name: "Item_Code",
            selector: "Item_Code",
          },
          {
            name: "Alternate_S/W_Code",
            selector: "Alternate_S/W_Code",
          },
          {
            name: "Item_Name",
            selector: "Item_Name",
            width: "240px",
            wrap:true,
          },
          {
            name: "HQ_Code",
            selector: "HQ_Code",
          },
          {
            name: "HQ_Name",
            selector: "HQ_Name",
          },
          {
            name: "Item_Division",
            selector: "Item_Division",
          },
          {
            name: "Site",
            selector: "Site",
          },
          {
            name: "Bill_Qty",
            selector: "Bill_Qty",
          },
          {
            name: "Free_Qty",
            selector: "Free_Qty",
          },          
          {
            name: "Amount_after_Discount",
            selector: "Amount_after_Discount",            
          },
        ];

       



    useEffect (() => {
        pagauth();
        loginAuthorized();
        
        
      }, []);

  return (
    <>
    <div className="">
    <Navbar />   
    </div>
    <div className='d-flex justify-content-center align-items-top bg-white vh-100'>
    <div className='mt-3 w-100'>
    <div className='bg-white p-3 rounded w-100'>
    <div className='bg-info w-25 rounded-1 mt-1'>Division Wise Sales Report SO/ASM :</div>
    <div className='d-flex mt-2 flex-column align-items-center'>
    <div id="collapseExample">
              <div className="card card-body bg-success-subtle">
                <div className="container" style={{ fontSize: "13px" }}>
                  <div className="row align-items-start">
                    <div className="col">
                      <div className="label">HQ Code: </div>
                      <button className='btn btn-sm btn-primary rounded-1' style={{width:"100px"}} onClick={hqcodelist}>Select</button>
                    </div>
                    <div className="col">
                      <div className="label">Select HQ Code: </div>
                      <select className='ms-0 rounded-1' style={{width:"150px"}} onChange={handlehqlistdata} >
                        <option defaultValue={true}>--Select--</option>
                        {
                          values.map((opt,i)=><option key={i}>{opt.hqCode}</option>)
                        }   
                        </select>
                    </div>
                    <div className="col">
                      <div className="label" style={{width:"200px"}}>HQ Name: </div>
                      <span className='ms-0 me-0'>{hqNamedata}</span>
                    </div>
                    <div className="col">
                      <div className="label" style={{width:"100px"}}>Sales Value: </div>
                      <span className='ms-0 me-0'>{hqdatasval}</span>
                    </div>
                    <div className="col">
                      <div className="label" style={{width:"180px"}}></div>
                      <button className='btn btn-sm btn-primary rounded-1' onClick={handlehqwisedata}>Generate HQ/Rs. Wise Pivot</button>
                      
                    </div>
                    <div className="col">
                      <div className="label" style={{width:"150px"}}></div>                      
                      <span style={{display:hqbtnstate}}><ExportToExcelLive reportLiveDetails={values} /></span>
                    </div>
                    </div>
                  </div>
                  </div>
                  </div>    
    <div className='mb-2'> <label style={{textAlign:"right", width:"100px"}}>From Date:-</label>    
    <input type="date" id="fromdate" onChange={(e) => handlefromDateUpdate(e)} />
    <label style={{textAlign:"right", width:"100px"}}>To Date:-</label>    
    <input style={{marginRight:"50px"}} type="date" id="todate" onChange={(e) => handletoDateUpdate(e)} />    
    </div>        
    <button className='btn btn-primary rounded-1' style={{display:btnsitestate}} onClick={empdivemapdata}>1. Select Division Data Details</button>
    <button className='btn btn-primary rounded-1' style={{display:btnrefstate}} onClick={empsitemapdata}>2. Select Site Data Details</button>
            <button className='btn btn-primary rounded-1' style={{display:btnreport}} disabled={btnreport} onClick={divwisereport}>3. Refresh Sales Report</button>
            <button className='btn btn-primary rounded-1' style={{display:btnfinalreport}} disabled={btnfinalreport} onClick={handlehqwisereport}>4. Search HQ Wise Sales Report</button>
            <div><span style={{fontSize:"12px"}}>Division:- {finaldivmapdata}</span></div>                           

   
  <DataTableExtensions
  columns={columns}
  data={filtereddata}
  exportHeaders={true}
  print={false}
  fileName={"Division_Wise_Sales_Report"}
  >
  
    <DataTable
    // title="HQ Wise For Month Sales Details:"
    // columns={columns}
    // data={filtereddata}
    customStyles={customStyles}
    pagination
    fixedHeader
    fixedHeaderScrollHeight="450px"   
    
    
    
    
    
    // selectableRows
    // selectableRowsHighlight
    
    // actions={<CSVLink data={filtereddata} filename='TMPerformanceReports' onClick={() => {}} className="btn btn-sm btn-info">Performance Data Export in Excel</CSVLink>}    
    // subHeader
    // subHeaderComponent={
    //   <input type='text' placeholder='Search here' className='w-25 form-control'
    //   value={search} onChange={(e) => setSearch(e.target.value.replace(/[^a-z^ -]/gi, ''))}
    //    />
    // }
    // subHeaderAlign='center' 
    
    
     /> 
     </DataTableExtensions>
    </div>    
            </div>
        </div>
    </div>
    </>
  )
}

export default Tcssoreport