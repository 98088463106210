import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Leveltilldata from './Leveltilldata';
import Navbar from '../Navbar';
import { toast } from 'react-toastify';






const Reportstillmonth = () => {
    
  const [userData, setUserData] = useState({});  
  const [dbddata, SetDbddata] = useState({});
  
  
  
  const navigate = useNavigate();
  
  
  
  const empdataHomePage = async () => {
    try {
        const res = await fetch('/user/empmasterdata');
        const empdata = await res.json();        
        setUserData(empdata); 
    } catch (err) {
        console.log(err);
        
    }

}

const currentdatadate = async () => {
  try {
    const dbres = await fetch('/homedb/dashboardata');
    const dbresdata = await dbres.json();
    SetDbddata(dbresdata);
    
  } catch (error) {
    console.log(error);
  }
}


const loginAuthorized = async () => {

  await axios.get('/user/loginauth')    
    .then(res => {
      if(res.data.Status === "Success")
    {      
      empdataHomePage();
      currentdatadate();
    }
    else
    {     
      navigate('/Login');
    }
  }).then(err => console.log(err));

}

const PageAuth = async() => {            
  try {
    const pageid = "protontmPage"
     await axios.get('/user/pageaccessdata/'+ pageid)
      .then(res => {
  if (res.data.Status === "NoAccessPage")
  {
      navigate('/Home');
      toast.error("You don't have access this page.", { position: "top-center" });
  }

      })
      .then(err => console.log(err))               
      
  } catch (err) {
      console.log(err);                
  }        
};

const pagauth = async() => {
  const pageids = "protontmPage";
  const res = await fetch('/user/pageaccessdatas/'+ pageids, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    credentials: "include"
  })

  const expdata = await res.json();  
  if(res.status === 404)
  {
    navigate('/Home');
    toast.error("You don't have access this page.", { position: "top-center" });
  }
}


useEffect (() => {
  // PageAuth();
  pagauth();
  loginAuthorized();
  
}, []);
  

  return (
    <>
    <div className="">
    <Navbar />   
    </div>
    <div className='d-flex justify-content-center align-items-top bg-white vh-100'>
    
    <div className='mt-3 w-100'>
    <div className='bg-white p-3 rounded w-100'>

    <div className='d-flex flex-column align-items-center'>    
            <Leveltilldata />
            </div>    
    </div>
        </div>
    </div>

    </>
  )
}

export default Reportstillmonth