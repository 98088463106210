import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
// import 'react-data-table-component-extensions/dist/utilities';
import {CSVLink} from 'react-csv';
import { ExportToExcelLive } from '../Excelfile';
import Navbar from '../Navbar';
import { toast } from 'react-toastify';


const Tcsinventory = () => {
    
    const navigate = useNavigate();

    
    const [userData, setUserData] = useState({});        
    const senempmapdata = userData.empCode;       
    const [values, setValues] = useState([]);
    const [selectinvsitedata, setSelectinvsitedata] = useState();
    const [selectinvlocationdata, setSelectinvlocationdata] = useState();
    const [finalinvdata, setFinalinvdata] = useState([]);
    const [btnsearch, setBtnsearch] = useState('');
        

    const empsitemapdata = async () => {
        
        try {
            if(!senempmapdata)
            {
                toast.error("Please Connect to QRS-Administration.", { position: "top-center" });
            }
            else
            {
            const res = await fetch('/tcsdb/sitemapdata/'+senempmapdata, {
                method: "GET",
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                    },
                    credentials: "include"  
            });
            const empsitemapdata = await res.json();
            if(res.status === 404)
            {
                toast.error("You have Zero Data Available.", { position: "top-center" });
            }
            else
            {            
            setValues(empsitemapdata);            
            }
        }
        } catch (err) {
            console.log(err);
            
        }

    }


    const empdataHomePage = async () => {
        try {
            const res = await fetch('/user/empmasterdata');
            const empdata = await res.json();        
            setUserData(empdata);             
        } catch (err) {
            console.log(err);
            
        }
    
    }

      const loginAuthorized = async () => {

        await axios.get('/user/loginauth')    
          .then(res => {
            if(res.data.Status === "Success")
          {      
            empdataHomePage();            
          }
          else
          {     
            navigate('/Login');
          }
        }).then(err => console.log(err));
      
      }      

    
    const divwiseinventoryreport = async () => {        
      try {
          let frontsitedata = selectinvsitedata;
          let frontlocationdata = selectinvlocationdata;
          let locationtypedata = "";
          let locationsdata = "";
          if(frontlocationdata === "Inventory")
          {
            locationtypedata = "Inventory";
            locationsdata = "FG Saleable Stock";
          }
          
          if(frontlocationdata === "Goods-intransit")
          {
            locationtypedata = "Goods-intransit";
            locationsdata = "Goods Intransit Location";
          }

          if(!frontsitedata ||!locationtypedata || !locationsdata || frontlocationdata === "--Select--"|| frontsitedata === "--Select--")
          {
              toast.error("Please Select the Site Data.", { position: "top-center" });
          }          
          else
          {
            setBtnsearch('none');
          const data = { entityNames:"", actions:"", sites:"", locationTypes:"", locations:"", itemTypes:"" };
          const res = await fetch('/tcsdb/divinvendata', {
              method: "POST",
              headers:{                  
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                entityNames:"itemInventory", actions: "SEARCH", sites:frontsitedata, locationTypes: locationtypedata, locations: locationsdata, itemTypes :"Finished Goods"
              })
            
          })          
          
          const invdata = await res.json();          
          setFinalinvdata(invdata);
          setBtnsearch('');
          toast.success(`${selectinvsitedata} Location ${locationtypedata} reports genrated Successfully...`, { position: "top-center" });                   
      } 
      } catch (err) {
          console.log(err);
          
      }
  }

    const pagauth = async() => {
        const pageids = "TcsInventoryReportPage";
        const res = await fetch('/user/pageaccessdatas/'+ pageids, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          credentials: "include"
        })        
        if(res.status === 404)
        {
          navigate('/Home');
          toast.error("You don't have access this page.", { position: "top-center" });
        }
      }


      const customStyles = {
        headRow: {
          style:{
            backgroundColor:'purple',
            color:'white',            
            height:80,                  
          },    
        },
        headCells:{
          style:{
            fontSize:'14px',
            
          }
        }
      }       

        const invcolumns = [
          {
              name: "Site",
              selector: "Site",
                            
            },
          {
            name: "Item_Code",
            selector: "itemCode",            
            
          },
          {
            name: "Item_Description",
            selector: "itemDescription",
            width:"240px",
            wrap:true,                        
          },
          {
            name: "Billing_UOM",
            selector: "uomCode",
          },
          {
            name: "Total_Quantity",
            selector: "totalQuantity",      
          },
          {
            name: "Batch_Number",
            selector: "batchNumber",
          },
          {
            name: "Batch_Mfg_Date",
            selector: "batchMfgDate",
            // sortable:true,
          },
          {
            name: "Batch_Exp_Date",
            selector: "batchExpDate",
          },          
          {
            name: "No_of_MonthsForExpiry",
            selector: "noOfMonthsForExpiry",
          },                  
        ];



    useEffect (() => {
        pagauth();
        loginAuthorized();
        
        
      }, []);
      

  return (
    <>
    <div className="">
    <Navbar />   
    </div>
    
    <div className='d-flex justify-content-center align-items-top bg-white vh-100'>
    <div className='mt-3 w-100'>
    <div className='bg-white p-3 rounded w-100'>
    <div className='bg-info w-25 rounded-1 mt-1'>Site Wise Batch Wise Inventory Details:</div>
    <div className='d-flex mt-2 flex-column align-items-center'>    
    <div className='w-100'>
    <button className='btn btn-primary rounded-1 ms-5 mb-2' onClick={empsitemapdata}>Site Details Click here!</button>    
    <select className='ms-5 w-25' onChange={(e)=> setSelectinvsitedata(e.target.value)} >
    <option defaultValue={true}>--Select--</option>
    {
      values.map((opt,i)=><option key={i}>{opt.siteCode}</option>)
    }   
    </select>
    <select className='ms-5 w-25' onChange={(e)=> setSelectinvlocationdata(e.target.value)}>
    <option defaultValue={true}>--Select--</option>
      <option>Inventory</option>
      <option>Goods-intransit</option>
    </select>
    <button className='btn btn-primary rounded-1 ms-5 mb-2' disabled={btnsearch} onClick={divwiseinventoryreport}>Search Report</button>    </div>          
  
      <DataTableExtensions
      columns={invcolumns}
    data={finalinvdata}
    // filter={false}
    exportHeaders={true}
    print={false}
    fileName={"Inventory_Report"}
      >
      <DataTable    
    customStyles={customStyles}
    pagination
    fixedHeader
    fixedHeaderScrollHeight="450px"
    />
      </DataTableExtensions>
    </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Tcsinventory