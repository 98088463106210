import React, { useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';



export const  ExportToExcel = ({reportDetails}) => {
    const fileType = "xlsx";
    const exportToCSV = () => {
        const reports1 = XLSX.utils.json_to_sheet(reportDetails);
        const sheetdata = {Sheets: {reports:reports1}, SheetNames:["reports"]};
        const excelBuffer = XLSX.write(sheetdata, {bookType:"xlsx", type:"array"});
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, "divisionwisesales"+".xlsx");
    }
    return(
        <>
        <button className='mt-2 btn btn-success' onClick={exportToCSV}>Divsion Wise Sales Report</button>
        </>
    )
};


export const  ExportToExceltill = ({reporttillDetails}) => {
    const fileType = "csv";
    const exportToCSV = () => {
        const reports1 = XLSX.utils.json_to_sheet(reporttillDetails);
        const sheetdata = {Sheets: {reports:reports1}, SheetNames:["reports"]};
        const excelBuffer = XLSX.write(sheetdata, {bookType:"csv", type:"array"});
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, "divisionwisesales"+".csv");
    }
    return(
        <>
        <button className='mt-2 btn btn-success' onClick={exportToCSV}>Divsion Wise Sales Report</button>
        </>
    )
};



export const  ExportToExcelLive = ({reportLiveDetails}) => {
    const fileType = "xlsx";
    const exportToCSV = () => {
        const reports1 = XLSX.utils.json_to_sheet(reportLiveDetails);
        const sheetdata = {Sheets: {reports:reports1}, SheetNames:["reports"]};
        const excelBuffer = XLSX.write(sheetdata, {bookType:"xlsx", type:"array"});
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, "hqwisevaluewise"+".xlsx");
    }
    return(
        <>
        <button className='mt-0 btn btn-success' style={{fontSize:"13px"}} onClick={exportToCSV}>Download Report</button>
        </>
    )
};

// export const PageAuth = async() => {
//     const [pagedb, setPagedb] = useState([]);
//     try {
//         const res = await fetch('/pageaccessdata');
//         const pageaccessdb = await res.json();        
//         setPagedb(pageaccessdb); 
//     } catch (err) {
//         console.log(err);
        
//     }

// };








