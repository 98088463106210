import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';





const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {    
    try {
      axios.get('/user/logout')
    navigate('/Login');
    toast.error(("Logout Successfully"), { position: "top-center" });
    } catch (error) {
      console.log(error);
    }   
    
  }

  return (
    <>

    <NavLink exact className="navbar-brand" to={"/"}>Login</NavLink>
      <nav className="navbar navbar-expand navbar-light bg-primary text-light fixed-top rounded-3">
        <div className="container-fluid">
          <NavLink exact className="navbar-brand text-white" to={"/Home"}>Home</NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">              
            <li className="nav-item dropdown">
                <NavLink exact className="nav-link dropdown-toggle text-white" id="reportnavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" to={""}>
                  Live Reports
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="reportnavbarDropdown">
              <li className="nav-item">
              <NavLink exact className="nav-link" style={{fontSize:"13px"}} to={"/Tcsreport"}>Live Sales Report</NavLink>              
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li className="nav-item">
              <NavLink exact className="nav-link" style={{fontSize:"13px"}} to={"/Tcssoreport"}>Live Sales Report_ASM</NavLink>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li className="nav-item">
              <NavLink exact className="nav-link" style={{fontSize:"13px"}} to={"/Tcssaleshisreport"}>Live Sales History Report</NavLink>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li className="nav-item">
              <NavLink exact className="nav-link" style={{fontSize:"13px"}} to={"/Tcsinventory"}>Live Item Inventory</NavLink>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li className="nav-item">
              <NavLink exact className="nav-link" style={{fontSize:"13px"}} to={"/Reportstillmonth"}>Pharma Till Months</NavLink>
              </li>
              </ul>
              </li>              
              <li className="nav-item dropdown">
                <NavLink exact className="nav-link dropdown-toggle text-white" id="masternavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" to={""}>
                  Master
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="masternavbarDropdown">
                  <li><NavLink className="dropdown-item" style={{fontSize:"13px"}} to={"/Signup"}>Signup</NavLink></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><NavLink className="dropdown-item" style={{fontSize:"13px"}} to={"/Employee"}>Employee Management</NavLink></li>               
                </ul>
              </li>
              <li className="nav-item">
              <NavLink exact className="nav-link text-white" to={"/Password"}>Change Password</NavLink>                
              </li>
            </ul>
            <form className="d-flex">
              {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" /> */}
              <button className="btn btn-sm btn-danger" onClick={handleLogout}>Logout</button>
              {/* <li className="nav-item">            
            <NavLink className="dropdown-item" to={"/Logout"}>Logout</NavLink>
            </li>  */}            
            </form>
          </div>
        </div>        
      </nav> 
      
    </>
  );
};
export default Navbar;
